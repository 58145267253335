import { VersionedTransaction } from '@solana/web3.js';
import { Network, PaymentMethod, isEvm } from '@spherelabs/common';
import { nanoid } from 'nanoid';
import qs from 'qs';

import { api } from '../api';
import { DEFAULT_API_ORIGIN } from '../constants/defaultApiOrigin';
import { Authentication3DSParams } from './getAuthentication3DSParams';

class SolPaymentTransactionError extends Error {
  private _details: string | null;

  constructor(
    public message: string,
    details?: string,
  ) {
    super(message);
    this.name = 'SolPaymentTransactionError';
    this._details = details ?? null;
  }

  public get details(): string | null {
    return this._details;
  }

  public set details(details: string) {
    this._details = details;
  }
}

export interface GetPaymentRequestUrlParams {
  email: string;
  selectedTokenAddress: string | undefined;
  quantities: string[];
  firstName: string;
  lastName: string;
  paymentLinkId: string;
  lineItemIds: string[];
  merchantAcceptedCurrency: string;
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  code?: string;
  solanaPayReference?: string;
  assetId?: string;
  network: Network;
  cardPaymentReference?: string;
  phaseLimitId?: string;
  customUnitAmount?: number;
  referenceId: string | null;
  skipPreflight: boolean | undefined;
  cardInfo?: {
    cardName: string;
    cardToken: string;
    cardExpYear: string;
    cardExpMonth: string;
    cardAddress1: string;
    cardAddress2: string;
    cardState: string;
    cardCity: string;
    cardCountry: string;
    cardZip: string;
    deviceId: string;
  };
  authentication3DS?: Authentication3DSParams | { transactionId: string };
  customFields?: { key: string; value: string }[];
}

export const getPaymentRequestUrl = (
  {
    city,
    country,
    email,
    selectedTokenAddress,
    line1,
    line2,
    firstName,
    lastName,
    paymentLinkId,
    lineItemIds,
    merchantAcceptedCurrency,
    postalCode,
    quantities,
    state,
    code,
    solanaPayReference,
    assetId,
    network,
    cardPaymentReference,
    phaseLimitId,
    customUnitAmount,
    cardInfo,
    customFields,
    referenceId,
    skipPreflight,
  }: GetPaymentRequestUrlParams,
  apiOrigin?: string,
) => {
  const paymentReference = nanoid();
  let basePath = isEvm(network)
    ? `/v1/public/paymentLink/eth/pay/${paymentLinkId}`
    : cardInfo
      ? `/v1/public/paymentLink/pay/fiat/${paymentLinkId}`
      : network === Network.BITCOIN
        ? `/v1/public/paymentLink/btc/pay/${paymentLinkId}`
        : `/v1/public/paymentLink/pay/${paymentLinkId}`;

  if (apiOrigin !== undefined) {
    try {
      const pathname = new URL(apiOrigin).pathname;
      if (pathname !== '/') {
        basePath = `${new URL(apiOrigin).pathname}${basePath}`;
      }
    } catch {
      // Ignore
    }
  }

  return {
    url: new URL(
      `${basePath}?${qs.stringify({
        referenceId: referenceId ?? undefined,
        inputMint:
          merchantAcceptedCurrency !== undefined &&
          !cardInfo &&
          merchantAcceptedCurrency !== selectedTokenAddress
            ? selectedTokenAddress
            : undefined,
        email: email.trim().length === 0 ? undefined : email.trim(),
        firstName: firstName.trim().length === 0 ? undefined : firstName.trim(),
        lastName: lastName.trim().length === 0 ? undefined : lastName.trim(),
        line1: line1.trim().length === 0 ? undefined : line1.trim(),
        line2: line2.trim().length === 0 ? undefined : line2.trim(),
        city: city.trim().length === 0 ? undefined : city.trim(),
        postalCode:
          postalCode.trim().length === 0 ? undefined : postalCode.trim(),
        state: state.trim().length === 0 ? undefined : state,
        country: country.trim().length === 0 ? undefined : country.trim(),
        lineItems: JSON.stringify(
          lineItemIds.map((lineItemId, i) => ({
            id: lineItemId,
            quantity: +quantities[i],
          })),
        ),
        code: code?.trim().length === 0 ? undefined : code?.trim(),
        solanaPayReference,
        paymentReference,
        assetId,
        network,
        cardPaymentReference,
        phaseLimitId,
        customUnitAmount,
        customFields: JSON.stringify(customFields),
        skipPreflight,
      })}`,
      apiOrigin ?? DEFAULT_API_ORIGIN,
    ),
    paymentReference,
  };
};

export interface GetPaymentTransactionParams
  extends GetPaymentRequestUrlParams {
  account: string;
}

export const getSolPaymentTransaction = async (
  { account, ...getPaymentRequestUrlParams }: GetPaymentTransactionParams,
  paymentMethod: PaymentMethod,
  apiOrigin?: string,
) => {
  const { url, paymentReference } = getPaymentRequestUrl(
    getPaymentRequestUrlParams,
    apiOrigin,
  );

  try {
    const response = await api.post(
      url.href,

      getPaymentRequestUrlParams.cardInfo
        ? JSON.stringify({
            account,
            cardToken: getPaymentRequestUrlParams.cardInfo.cardToken,
            expMonth: getPaymentRequestUrlParams.cardInfo.cardExpMonth,
            expYear: getPaymentRequestUrlParams.cardInfo.cardExpYear,
            billingName: getPaymentRequestUrlParams.cardInfo.cardName,
            billingAddress1: getPaymentRequestUrlParams.cardInfo.cardAddress1,
            billingAddress2: getPaymentRequestUrlParams.cardInfo.cardAddress2,
            billingCity: getPaymentRequestUrlParams.cardInfo.cardCity,
            billingState: getPaymentRequestUrlParams.cardInfo.cardState,
            billingCountry: getPaymentRequestUrlParams.cardInfo.cardCountry,
            billingPostalCode: getPaymentRequestUrlParams.cardInfo.cardZip,
            deviceId: getPaymentRequestUrlParams.cardInfo.deviceId,
            email: getPaymentRequestUrlParams.email,
            authentication3DS: getPaymentRequestUrlParams.authentication3DS,
          })
        : JSON.stringify({
            account,
            skipPreflight: getPaymentRequestUrlParams.skipPreflight,
          }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      },
    );

    const {
      transaction: serializedTransaction,
      paymentId,
      subscriptionId,
      ...rest
    } = response.data;

    if (serializedTransaction === undefined) {
      const error = new SolPaymentTransactionError(
        `An error occurred during payment. ${
          paymentMethod === PaymentMethod.CARD
            ? 'If your card has already been charged, the payment will be refunded within 14 working days.'
            : 'Try paying with another token instead.'
        }`,
        JSON.stringify(rest),
      );

      throw error;
    }

    const transaction = VersionedTransaction.deserialize(
      Uint8Array.from(Buffer.from(serializedTransaction, 'base64')),
    );

    return { transaction, paymentReference, paymentId, subscriptionId };
  } catch (e: any) {
    if (e.response?.data) {
      if (e.response.status === 412) {
        throw e;
      }
      const error = new SolPaymentTransactionError(
        `An error occurred during payment. ${
          paymentMethod === PaymentMethod.CARD
            ? 'If your card has already been charged, the payment will be refunded within 14 working days.'
            : 'Try paying with another token instead.'
        }`,
        JSON.stringify(e.response.data),
      );

      throw error;
    }

    throw e;
  }
};

export const getEvmPaymentTransaction = async (
  { account, ...getPaymentRequestUrlParams }: GetPaymentTransactionParams,
  apiOrigin?: string,
) => {
  const { url } = getPaymentRequestUrl(getPaymentRequestUrlParams, apiOrigin);

  const { functionName, contractAddress, inputData, paymentId } = await fetch(
    url.href,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        account,
      }),
    },
  ).then((response) => response.json());

  const isNativeTransfer = functionName === 'payETH';
  const args: string[] = isNativeTransfer
    ? [
        inputData.paymentId,
        inputData.amount,
        inputData.receiver,
        inputData.deadline,
        inputData.signature,
      ]
    : [
        inputData.paymentId,
        inputData.amount,
        inputData.token,
        inputData.receiver,
        inputData.deadline,
        inputData.signature,
      ];
  const value = isNativeTransfer ? BigInt(inputData.amount) : undefined;

  return {
    functionName: functionName as string,
    contractAddress: contractAddress as `0x${string}`,
    args,
    paymentId: paymentId as string,
    value,
  };
};
