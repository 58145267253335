import './SphereModal.css';

import React from 'react';

import { clsx } from '../../../utils';

export const SphereModal = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { as?: React.ElementType<any> }
>(function SphereModal({ className, as = 'div', ...rest }, ref) {
  const As = as;
  return <As className={clsx('sphere-modal', className)} ref={ref} {...rest} />;
});
