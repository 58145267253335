import './SphereInput.css';

import React from 'react';

import { clsx } from '../../../utils/clsx';

export interface SphereInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
}

export const SphereInput = React.forwardRef<HTMLInputElement, SphereInputProps>(
  function SphereInput(
    { startAdornment, endAdornment, className, error, disabled, ...rest },
    ref,
  ) {
    return (
      <div
        className={clsx(
          'sphere-input',
          error && 'sphere-input--error',
          disabled && 'sphere-input--disabled',
          className,
        )}
      >
        <div className='sphere-input__start-adornment'>{startAdornment}</div>
        <input
          disabled={disabled}
          ref={ref}
          className={clsx(
            'sphere-input__input',
            className?.includes('text-center') && 'text-center',
          )}
          {...rest}
        />
        {!!endAdornment &&
          (endAdornment === '%' ? (
            <span> {endAdornment} </span>
          ) : (
            <div className='sphere-input__end-adornment'>{endAdornment}</div>
          ))}
      </div>
    );
  },
);

export interface SphereTextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: boolean;
  disabled?: boolean;
  count?: number;
  maxLength?: number;
}

export function SphereTextArea({
  className,
  error,
  disabled,
  count,
  maxLength = 500,
  ...rest
}: SphereTextAreaProps) {
  const isDescription = count !== undefined && count !== null;

  return (
    <div className={isDescription ? 'relative' : ''}>
      <div
        className={clsx(
          'sphere-input',
          error && 'sphere-input--error',
          disabled && 'sphere-input--disabled',
          'sphere-input--textarea',
          className,
          isDescription ? 'sphere-input--textarea--description' : '',
        )}
      >
        <textarea
          disabled={disabled}
          className={clsx(
            'sphere-input__input sphere-input__input--textarea',
            isDescription ? 'sphere-input__input--textarea--description' : '',
          )}
          {...rest}
        />
      </div>
      {isDescription && (
        <div className='sphere-input__input--textarea--description-char-count'>
          {isDescription ? (
            <>
              <span className={`${count > maxLength ? '!text-red-400' : ''}`}>
                {count}
              </span>
              /{maxLength}
            </>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
}
