import './OptionalImage.css';

import { useState } from 'react';
import React from 'react';

import { clsx } from '../../../utils/clsx';
import ImageIcon from '../ImageIcon';

export interface OptionalImageProps {
  src?: string;
  customImageComponent?: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

export const OptionalImage = React.memo(function OptionalImage({
  src,
  className,
  customImageComponent,
  onClick,
  ...rest
}: OptionalImageProps) {
  const [isError, setIsError] = useState(false);

  if (isError || src === undefined || src?.trim() === '') {
    return (
      <div
        className={clsx('sphere-optional-image__error-container', className)}
      >
        <ImageIcon className='sphere-optional-image__icon' opacity={0.9} />
      </div>
    );
  }

  if (customImageComponent) {
    return <>{customImageComponent}</>;
  }

  const RenderedComponent = onClick ? 'button' : 'div';

  return (
    <>
      <img
        className='sphere-optional-image__dummy-image'
        src={src}
        alt='dummy-image'
        onError={() => {
          setIsError(true);
        }}
      />
      <RenderedComponent
        className={clsx(
          className,
          onClick && 'sphere-optional-image__clickable-image',
        )}
        style={{
          backgroundImage: `url(${src})`,
        }}
        {...(onClick && { onClick })}
        {...rest}
      />
    </>
  );
});
