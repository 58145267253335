import './SphereButton.css';

import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ElementType,
  ReactNode,
} from 'react';
import React from 'react';

import { clsx } from '../../../utils';
import { SphereSpinner } from '../SphereSpinner';
import { Typography } from '../Typography';

export interface SphereButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?:
    | 'contained'
    | 'outlined'
    | 'text'
    | 'secondary'
    | 'dark'
    | 'merchant-verification';
  children?: ReactNode;
  as?: ElementType<any>;
  className?: string;
  loading?: boolean;

  [otherProps: string]: any;
}

export const SphereButton = React.forwardRef(function SphereButton(
  {
    as = 'button',
    className,
    variant = 'contained',
    disabled: disabledProp,
    loading,
    children,
    ...rest
  }: SphereButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement> | null,
) {
  const disabled = disabledProp || loading;

  return (
    <Typography
      variant='lg'
      as={as}
      className={clsx(
        variant !== 'text' && 'sphere-button',
        variant === 'text' && 'sphere-button-text',
        variant === 'contained' && 'sphere-button--contained',
        variant === 'outlined' && 'sphere-button--outlined',
        variant === 'secondary' && 'sphere-button--secondary',
        variant === 'dark' && 'sphere-button--dark',
        variant === 'merchant-verification' &&
          'sphere-button--merchant-verification',
        disabled &&
          variant === 'contained' &&
          'sphere-button--contained--disabled',
        disabled &&
          variant === 'outlined' &&
          'sphere-button--outlined--disabled',
        disabled && variant === 'dark' && 'sphere-button--dark--disabled',
        loading && 'sphere-button--loading',
        className,
      )}
      disabled={disabled}
      {...rest}
      ref={ref}
    >
      {loading ? <SphereSpinner /> : children}
    </Typography>
  );
});
