import { SVGProps } from 'react';

export default function CheckBox({
  checked,
  ...rest
}: SVGProps<SVGSVGElement> & { checked: boolean }) {
  if (!checked) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='22'
        height='22'
        fill='none'
        viewBox='0 0 22 22'
        {...rest}
      >
        <path
          stroke='#75767B'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10z'
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      fill='none'
      viewBox='0 0 22 22'
      {...rest}
    >
      <path
        stroke='#3B6EFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M6.5 11l3 3 6-6m5.5 3c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10z'
      />
    </svg>
  );
}
