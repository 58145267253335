import Decimal from 'decimal.js';

export const MAX_DP = 5;

export const formatToHumanReadableAmount = ({
  rawAmount,
  decimals,
}: {
  rawAmount: string;
  decimals: number;
}) => {
  const humanReadableAmount = rawAmount
    ? new Decimal(rawAmount).dividedBy(10 ** decimals)
    : new Decimal(0);

  const formatted = formatToDecimalPoints(
    humanReadableAmount.toNumber(),
    Math.min(MAX_DP, decimals),
  );

  if (humanReadableAmount.toNumber() === 0) {
    return '0';
  }
  if (formatted === '0' && decimals > MAX_DP) {
    return `< 0.${Array.from({ length: MAX_DP - 1 }, () => '0').join('')}1`;
  }

  return formatted;
};

export const formatToDecimalPoints = (num: number, decimalPlaces: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: decimalPlaces,
  });
  return formatter.format(num);
};
