import {
  type FetchQuotesParams,
  isEvm,
  type JupiterRoute,
  Network,
  type Token,
  type TokensRouteMap,
} from '@spherelabs/common';
import axios from 'axios';
import { useMemo } from 'react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { USDC_TOKEN } from '../constants/commonTokens';
import { DEFAULT_API_ORIGIN } from '../constants/defaultApiOrigin';

export const useTokensRouteMap = () => {
  const swr = useSWRImmutable<TokensRouteMap[]>(
    `${DEFAULT_API_ORIGIN}/v1/supportedToken`,
    (url) => fetch(url).then((response) => response.json()),
    {
      shouldRetryOnError: true,
    },
  );

  return swr;
};

export const useTokenInfo = (
  tokenAddress: string | undefined,
  network: Network = Network.SOL,
) => {
  const { data: tokensRouteMap } = useTokensRouteMap();
  const tokenRouteMap = useMemo(
    () =>
      tokensRouteMap?.find(
        (token) => token.inputToken.address === tokenAddress,
      ),
    [tokenAddress, tokensRouteMap],
  );

  const evmTokenInfo = useEvmTokenInfo(tokenAddress, network);
  const btcTokenInfo = useBtcTokenInfo(tokenAddress);

  return network === Network.SOL
    ? tokenRouteMap?.inputToken
    : network === Network.BITCOIN
      ? btcTokenInfo
      : evmTokenInfo;
};

export const useTokensInfos = () => {
  const { data: tokensRouteMap } = useTokensRouteMap();

  const tokensInfos = useMemo(
    () => tokensRouteMap?.map((token) => token.inputToken),
    [tokensRouteMap],
  );

  const evmTokens = useEvmTokens();
  const btcTokens = useBtcTokens();

  const results = useMemo(() => {
    if (!tokensInfos || !evmTokens.data || !btcTokens.data) {
      return undefined;
    }
    return [
      ...tokensInfos,
      ...evmTokens.data.flatMap(({ tokens }) => tokens),
      ...btcTokens.data,
    ];
  }, [tokensInfos, evmTokens.data, btcTokens.data]);

  return results;
};

export const usePossibleInputTokens = (outputToken?: Token) => {
  const { data: tokensRouteMap } = useTokensRouteMap();
  const { data } = useSWR(
    !tokensRouteMap
      ? null
      : ['possibleInputTokens', tokensRouteMap, outputToken],
    ([_, tokensRouteMap, outputToken]) =>
      tokensRouteMap
        .reduce<Token[]>((acc, curr) => {
          const isPossibleInput = !outputToken
            ? true
            : curr.inputToken.address === outputToken.address ||
              curr.outputTokens.some(
                (token) => token.address === outputToken.address,
              );
          if (isPossibleInput) {
            acc.push(curr.inputToken);
          }
          return acc;
        }, [])
        .sort((a, b) => a.name.localeCompare(b.name)),
  );

  return data;
};

const POPULAR_TOKENS = {
  SOL: 'So11111111111111111111111111111111111111112',
  USDC: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  USDT: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  mSOL: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
  stSOL: '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj',
  ETH: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
};
export const useDefaultTokensShown = ({
  outputToken,
  selectedToken,
}: {
  outputToken?: Token;
  selectedToken: Token | null;
}) => {
  const { data: tokensRouteMap } = useTokensRouteMap();
  const { data } = useSWR(
    !tokensRouteMap
      ? null
      : ['defaultTokensShown', tokensRouteMap, outputToken, selectedToken],
    ([_, tokensRouteMap, outputToken, selectedToken]) => {
      const popularTokensRouteMap = Object.values(POPULAR_TOKENS).map(
        (popularTokenAddress) =>
          tokensRouteMap.find(
            (tokenRouteMap) =>
              tokenRouteMap.inputToken.address === popularTokenAddress,
          ),
      );

      const isContainsUndefined = popularTokensRouteMap.some(
        (routeMap) => routeMap === undefined,
      );
      if (isContainsUndefined) {
        return undefined;
      }

      const possiblePopularTokens = (popularTokensRouteMap as TokensRouteMap[])
        .filter((routeMap) => {
          if (outputToken === undefined) {
            return true;
          }
          return (
            routeMap.inputToken.address === outputToken.address ||
            routeMap.outputTokens.some(
              (token) => token.address === outputToken.address,
            )
          );
        })
        .map((routeMap) => routeMap.inputToken);

      if (
        outputToken !== undefined &&
        !Object.values(POPULAR_TOKENS).includes(outputToken.address)
      ) {
        possiblePopularTokens.unshift(outputToken);
      }

      if (
        selectedToken !== null &&
        !Object.values(POPULAR_TOKENS).includes(selectedToken.address) &&
        outputToken?.address !== selectedToken.address
      ) {
        possiblePopularTokens.unshift(selectedToken);
      }

      return possiblePopularTokens;
    },
  );

  return data;
};

export const useJupiterQuote = (
  params: FetchQuotesParams | undefined,
  isEnabled: boolean,
  allowMultiHopJupSwaps?: boolean,
) => {
  const swr = useSWR(
    ['jupiterQuote', params],
    !isEnabled || !params || params.inputMint === params.outputMint
      ? null
      : async ([_, params]) => {
          return axios
            .get<JupiterRoute>(
              `https://quote-api.jup.ag/v6/quote?inputMint=${
                params?.inputMint
              }&outputMint=${params?.outputMint}&amount=${
                params?.rawAmount
              }&slippageBps=${params?.slippageBps ?? 50}&swapMode=${
                params?.swapMode ?? 'ExactOut'
              }`,
              {
                params: {
                  onlyDirectRoutes: allowMultiHopJupSwaps ? 'false' : 'true',
                },
              },
            )
            .then((response) => response.data);
        },

    {
      refreshInterval: 20000,
    },
  );

  return swr;
};

// EVM tokens
export const useEvmTokens = () => {
  const swr = useSWRImmutable(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/supportedToken/evm`, // FIXME: use DEFAULT_API_ORIGIN
    (url) =>
      fetch(url).then((response) => response.json()) as Promise<
        Array<{
          name: string;
          tokens: Token[];
        }>
      >,
    {
      shouldRetryOnError: true,
    },
  );

  return swr;
};

// BTC tokens
export const useBtcTokens = () => {
  const swr = useSWRImmutable(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/supportedToken/bitcoin`, // FIXME: use DEFAULT_API_ORIGIN
    (key) => axios.get<Token[]>(key).then((response) => response.data),
    {
      shouldRetryOnError: true,
    },
  );

  return swr;
};

export const useTokensForEvmNetwork = (network: Network | undefined) => {
  const { data } = useEvmTokens();

  const tokens = useMemo(
    () =>
      !data || !network || !isEvm(network)
        ? undefined
        : data.find((chain) => chain.name === network.toString())?.tokens,
    [data, network],
  );

  return tokens;
};

export const useEvmTokenInfo = (
  tokenAddress: string | undefined,
  network: Network,
) => {
  const tokens = useTokensForEvmNetwork(network);

  const tokenInfo = useMemo(
    () => tokens?.find((token) => token.address === tokenAddress),
    [tokenAddress, tokens],
  );

  return tokenInfo;
};

export const useBtcTokenInfo = (tokenAddress: string | undefined) => {
  const { data: tokens } = useBtcTokens();

  const tokenInfo = useMemo(
    () => tokens?.find((token) => token.address === tokenAddress),
    [tokenAddress, tokens],
  );

  return tokenInfo;
};
