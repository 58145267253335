import { findReference, FindReferenceError } from '@solana/pay';
import { Connection, Keypair, PublicKey } from '@solana/web3.js';
import { Dispatch, SetStateAction } from 'react';

export default async function getSolanaPayTransaction(
  connection: Connection,
  reference: PublicKey,
  setReference: Dispatch<SetStateAction<PublicKey>>,
) {
  try {
    const confirmedSignatureInfo = await findReference(connection, reference, {
      finality: 'confirmed',
    });

    // generate new reference key
    setReference(Keypair.generate().publicKey);

    return confirmedSignatureInfo.signature;
  } catch (e) {
    // If current reference not found, ignore error
    if (e instanceof FindReferenceError) {
      return;
    }
    throw e;
  }
}
