import { Dialog } from '@headlessui/react';
import { isTestnet, Network } from '@spherelabs/common';
import { useEffect, useState } from 'react';

import { clsx } from '../../../utils/clsx';
import { getHumanReadableNetworkName } from '../../../utils/networkUtils';
import { CloseButton, SphereDialog, Typography } from '../../css';
import AppCheckBox from '../AppCheckbox';
import { NetworkLogo } from '../NetworkLogo';
import { NetworkSelectorProps } from './NetworkSelectorProps';

const styles = {
  dialogItem: ({ selected }: { selected: boolean }) =>
    clsx(
      `
        px-3 
        flex
        items-center 
        border-2 
        border-transparent 
        border-solid 
        rounded-full
        cursor-pointer
        mb-3
        hover:bg-gray-100
        focus:bg-gray-100
        h-[3.625rem]
        gap-8
      `,
      selected && '!border-primary',
    ),
};

interface NetworkSelectorDialogProps
  extends Omit<
    NetworkSelectorProps,
    'className' | 'disabled' | 'availableNetworks' | 'network'
  > {
  network?: Network;
  open: boolean;
  onClose: () => void;
}

export const NetworkSelectorDialog = ({
  onChange,
  onClose,
  open,
  network: selectedNetwork,
  supportedNetworks,
  allowHideTestnets,
}: NetworkSelectorDialogProps) => {
  const [shouldIncludeTestnets, setShouldIncludeTestnets] = useState(
    (selectedNetwork && isTestnet(selectedNetwork)) || !allowHideTestnets,
  );

  const filteredSupportedNetworks = supportedNetworks?.filter((network) =>
    !allowHideTestnets || shouldIncludeTestnets ? true : !isTestnet(network),
  );

  useEffect(
    function resetSelectedNetwork() {
      if (
        !shouldIncludeTestnets &&
        selectedNetwork &&
        isTestnet(selectedNetwork) &&
        filteredSupportedNetworks
      ) {
        // onChange(filteredSupportedNetworks[0]);
      }
    },
    [
      filteredSupportedNetworks,
      onChange,
      selectedNetwork,
      shouldIncludeTestnets,
      supportedNetworks,
    ],
  );

  if (!filteredSupportedNetworks) {
    return null;
  }

  return (
    <SphereDialog open={open} onClose={onClose}>
      <Dialog.Title as='div' className='flex items-center justify-between'>
        <Typography variant='h6'>Select network</Typography>
        <CloseButton onClick={onClose} />
      </Dialog.Title>
      <ul className='mt-[1.125rem]'>
        {filteredSupportedNetworks.map((network) => (
          <li
            key={network.toString()}
            className={styles.dialogItem({
              selected: network === selectedNetwork,
            })}
            onClick={() => {
              onChange(network);
              onClose();
            }}
          >
            <NetworkLogo network={network} />
            <Typography variant='lg' noWrap className='!font-semibold'>
              {getHumanReadableNetworkName(network)}
            </Typography>
          </li>
        ))}
      </ul>
      {allowHideTestnets && (
        <AppCheckBox
          label='Include Testnets'
          checked={shouldIncludeTestnets}
          onChange={(e) => {
            setShouldIncludeTestnets(e.target.checked);
          }}
        />
      )}
    </SphereDialog>
  );
};
