'use client';

import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { http, WagmiProvider } from 'wagmi';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  gnosis,
  sepolia,
  localhost,
  bsc,
  avalanche,
} from 'wagmi/chains';
import { PropsWithChildren } from 'react';

const getInfuraUrl = (network: string) =>
  `https://${network}.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_API_KEY}`;

const getAlchemyUrl = (network: string) =>
  `https://${network}.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`;

export const DEFAULT_RPC_URLS: Record<number, string> = {
  [mainnet.id]: getInfuraUrl('mainnet'),
  [polygon.id]: getInfuraUrl('polygon-mainnet'),
  [sepolia.id]: getInfuraUrl('sepolia'),
  [optimism.id]: getInfuraUrl('optimism-mainnet'),
  [arbitrum.id]: getInfuraUrl('arbitrum-mainnet'),
  [base.id]: getAlchemyUrl('base-mainnet'),
  [avalanche.id]: getInfuraUrl('avalanche-mainnet'),
  [bsc.id]: getInfuraUrl('bsc-mainnet'),
};

const config = getDefaultConfig({
  appName: 'Sphere',
  projectId: 'cfffcc860ed87aab573b73b666f3a439', // walletconnect project id
  chains: [mainnet, polygon, optimism, arbitrum, base, avalanche, bsc, sepolia],
  transports: {
    [mainnet.id]: http(DEFAULT_RPC_URLS[mainnet.id]),
    [polygon.id]: http(DEFAULT_RPC_URLS[polygon.id]),
    [optimism.id]: http(DEFAULT_RPC_URLS[optimism.id]),
    [arbitrum.id]: http(DEFAULT_RPC_URLS[arbitrum.id]),
    [base.id]: http(DEFAULT_RPC_URLS[base.id]),
    [avalanche.id]: http(DEFAULT_RPC_URLS[avalanche.id]),
    [bsc.id]: http(DEFAULT_RPC_URLS[bsc.id]),
    [sepolia.id]: http(DEFAULT_RPC_URLS[sepolia.id]),
  },
});

const queryClient = new QueryClient();

export default function EvmWalletProvider({ children }: PropsWithChildren) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
