import './SphereModalBackdrop.css';

import React from 'react';

import { clsx } from '../../../utils/clsx';

export const SphereModalBackdrop = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(function SphereModalBackdrop({ className, ...rest }, ref) {
  return (
    <div
      className={clsx('sphere-modal-backdrop', className)}
      {...rest}
      ref={ref}
    />
  );
});
