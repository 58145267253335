import { SVGProps } from 'react';

export function CloseIcon({
  color,
  ...rest
}: SVGProps<SVGSVGElement> & { color?: string }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...rest}>
      <path
        fill={color}
        d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'
      />
    </svg>
  );
}

export function CloseIconV7({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
      {...rest}
    >
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M18 2L2 18M2 2l16 16'
      />
    </svg>
  );
}
