import './SphereSpinner.css';

import { clsx } from '../../../utils/clsx';

interface SpinnerProps {
  className?: string;
}

export function SphereSpinner({ className, ...rest }: SpinnerProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      className={clsx('sphere-spinner', className)}
      {...rest}
    >
      <circle
        cx='12'
        cy='12'
        r='10'
        stroke='currentColor'
        strokeWidth='4'
        className='sphere-spinner__track'
      />
      <path
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        className='sphere-spinner__arc'
      />
    </svg>
  );
}
