import { Network, PayoutNetwork, WidenEnum } from '@spherelabs/common';

import { ArbitrumLogo } from '../../icons/networks/ArbitrumLogo';
import { BitcoinLogo } from '../../icons/networks/BitcoinLogo';
import { BscLogo } from '../../icons/networks/BscLogo';
import { EthereumLogo } from '../../icons/networks/EthereumLogo';
import { GnosisLogo } from '../../icons/networks/GnosisLogo';
import { OptimismLogo } from '../../icons/networks/OptimismLogo';
import { PolygonLogo } from '../../icons/networks/PolygonLogo';
import { SolanaLogo } from '../../icons/networks/SolanaLogo';
import { StripeLogo } from '../../icons/networks/StripeLogo';
import { BaseLogo } from '../../icons/networks/BaseLogo';
import { AvaxLogo } from '../../icons/networks/AvaxLogo';

interface NetworkLogoProps {
  network: WidenEnum<Network>;
  className?: string;
  borderRadius?: string;
}

export const NetworkLogo = ({
  network,
  className,
  borderRadius,
}: NetworkLogoProps) => {
  switch (network) {
    case Network.SOL:
      return <SolanaLogo className={className} borderRadius={borderRadius} />;
    case Network.ETH_MAINNET:
    case Network.SEPOLIA_TESTNET:
    case Network.LOCALHOST:
      return <EthereumLogo className={className} borderRadius={borderRadius} />;
    case Network.POLYGON_MAINNET:
      return <PolygonLogo className={className} borderRadius={borderRadius} />;
    case Network.GNOSIS_MAINNET:
      return <GnosisLogo className={className} borderRadius={borderRadius} />;
    case Network.OPTIMISM_MAINNET:
      return <OptimismLogo className={className} borderRadius={borderRadius} />;
    case Network.ARBITRUM_MAINNET:
      return <ArbitrumLogo className={className} borderRadius={borderRadius} />;
    case Network.BSC_MAINNET:
      return <BscLogo className={className} borderRadius={borderRadius} />;
    case Network.BITCOIN:
      return <BitcoinLogo className={className} borderRadius={borderRadius} />;
    case Network.STRIPE:
      return <StripeLogo className={className} borderRadius={borderRadius} />;
    case Network.BASE_MAINNET:
      return <BaseLogo className={className} borderRadius={borderRadius} />;
    case Network.AVALANCHE_MAINNET:
      return <AvaxLogo className={className} borderRadius={borderRadius} />;
    default:
      return null;
  }
};

interface PayoutNetworkLogoProps {
  payoutNetwork: PayoutNetwork;
  className?: string;
}

export const PayoutNetworkLogo = ({
  payoutNetwork,
  className,
}: PayoutNetworkLogoProps) => {
  // TODO: The rest of the payout networks.
  switch (payoutNetwork) {
    case PayoutNetwork.ETH:
      return <EthereumLogo className={className} />;
    default:
      return null;
  }
};
