import './CloseButton.css';

import { clsx } from '../../../utils/clsx';
import { CloseIcon } from '../CloseIcon';

export const CloseButton = ({
  className,
  ...rest
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => (
  <button className={clsx('sphere-close-button', className)} {...rest}>
    <CloseIcon width={20} height={20} color='#75767b' />
  </button>
);
