import { cn } from '../../utils';

export const ArbitrumLogo = ({
  className,
  borderRadius = '0%',
}: { className?: string; borderRadius?: string }) => (
  <div
    style={{
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: borderRadius,
      backgroundImage: `url("/icons/networks/arbitrum.svg")`,
    }}
    className={cn('w-8 aspect-square', className)}
  />
);
