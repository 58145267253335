import { cn } from '../../utils';

export const AvaxLogo = ({
  className,
  borderRadius = '50%',
}: { className?: string; borderRadius?: string }) => (
  <div
    style={{
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius,
      backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTIzIDVINXYxOGgxOFY1WiIvPjxwYXRoIGZpbGw9IiNFODQxNDIiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTE0IDI4Yy03LjUxMy4wMDgtMTQtNi40ODctMTQtMTRDMCA2LjE5NiA2LjA0My0uMDA4IDE0IDBjNy45NS4wMDggMTQgNi4xOTYgMTQgMTQgMCA3LjUwNS02LjQ5NSAxMy45OTItMTQgMTRabS0zLjk3MS03LjQzNkg3LjMxNWMtLjU3IDAtLjg1MSAwLTEuMDIzLS4xMWEuNjkuNjkgMCAwIDEtLjMxMy0uNTRjLS4wMS0uMjAyLjEzLS40NS40MTItLjk0NGw2LjctMTEuODA5Yy4yODUtLjUwMS40My0uNzUyLjYxMi0uODQ1LjE5NS0uMS40MjktLjEuNjI1IDAgLjE4Mi4wOTMuMzI2LjM0NC42MTEuODQ1bDEuMzc3IDIuNDA0LjAwNy4wMTNjLjMwOC41MzguNDY0LjgxLjUzMyAxLjA5N2EyLjA0IDIuMDQgMCAwIDEgMCAuOTU0Yy0uMDcuMjg5LS4yMjQuNTY0LS41MzYgMS4xMWwtMy41MiA2LjIyLS4wMDkuMDE3Yy0uMzEuNTQyLS40NjcuODE3LS42ODQgMS4wMjRhMi4wNDggMi4wNDggMCAwIDEtLjgzNS40ODVjLS4yODUuMDc5LS42MDQuMDc5LTEuMjQzLjA3OVptNi44NTIgMGgzLjg4OGMuNTc0IDAgLjg2MiAwIDEuMDM0LS4xMTNhLjY4Ny42ODcgMCAwIDAgLjMxMy0uNTQzYy4wMS0uMTk2LS4xMjgtLjQzNC0uMzk4LS45YTguMTk4IDguMTk4IDAgMCAxLS4wMjgtLjA0OGwtMS45NDgtMy4zMzItLjAyMi0uMDM3Yy0uMjc0LS40NjMtLjQxMi0uNjk3LS41OS0uNzg3YS42ODQuNjg0IDAgMCAwLS42MjEgMGMtLjE3OS4wOTMtLjMyMy4zMzctLjYwOC44MjhsLTEuOTQgMy4zMzEtLjAwNy4wMTJjLS4yODQuNDktLjQyNi43MzUtLjQxNi45MzYuMDE0LjIyLjEyNy40MjMuMzEzLjU0My4xNjguMTEuNDU2LjExIDEuMDMuMTFaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4K")`,
    }}
    className={cn('w-8 aspect-square', className)}
  />
);
