import { ChangeEvent, ChangeEventHandler } from 'react';

const getIsInteger = (value: string) => parseFloat(value) % 1 === 0;

interface GetIsNumberOptions {
  decimalPlaces: number;
  allowNegative: boolean;
  max?: number;
  min?: number;
}

const defaultOptions: GetIsNumberOptions = {
  allowNegative: false,
  decimalPlaces: 0,
};

const getIsNumberString = (
  value: string,
  options: GetIsNumberOptions = defaultOptions,
) => {
  const unformattedValue = value.replace(/[^0-9.]/g, '');
  if (unformattedValue === '') {
    return true;
  }

  if (unformattedValue === '-' && options.allowNegative) {
    return true;
  }

  // causes issues with input validation
  // if (value === "." && options.decimalPlaces > 0) {
  //   return true;
  // }

  if (Number.isNaN(Number(unformattedValue))) {
    return false;
  }

  const floatValue = parseFloat(unformattedValue);
  if (options.decimalPlaces === 0 && !getIsInteger(unformattedValue)) {
    return false;
  }

  const numberOfDecimalPlaces = (unformattedValue.split('.')[1] || '').length;
  if (
    options.decimalPlaces > 0 &&
    numberOfDecimalPlaces > options.decimalPlaces
  ) {
    return false;
  }

  if (!options.allowNegative && floatValue < 0) {
    return false;
  }

  if (options.max !== undefined && floatValue > options.max) {
    return false;
  }

  if (options.min !== undefined && floatValue < options.min) {
    return false;
  }

  return true;
};

const removeLeadingZeros = (value: string) =>
  value.replace(/^(-)?0+(0\.|\d)/, '$1$2');

export const handleNumberInput =
  (
    onChange: ChangeEventHandler<HTMLInputElement>,
    options: GetIsNumberOptions = defaultOptions,
  ) =>
  (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (getIsNumberString(value, options)) {
      e.target.value = removeLeadingZeros(value).trim();
      onChange(e);
    }
  };

export const handleNumberInputWithValue = (
  value: string,
  options: GetIsNumberOptions = defaultOptions,
) => {
  if (getIsNumberString(value, options)) {
    return removeLeadingZeros(value).trim();
  }
};
