import { InputHTMLAttributes } from 'react';

import CheckBox from '../../icons/CheckBox';
import { clsx } from '../../utils/clsx';
import { Typography } from '../css/Typography';

const styles = {
  root: clsx`
    inline-flex 
    items-center 
    gap-1
    cursor-pointer
  `,
  checkboxContainer: clsx`
    relative
  `,
  hiddenActualInput: clsx`
    absolute
    top-0
    left-0
    w-full  
    h-full
    opacity-0
    cursor-pointer
  `,
  label: ({ checked }: { checked: boolean }) =>
    clsx('select-none', checked ? 'text-[#2C61F9]' : 'text-[#75767B]'),
};

interface AppCheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  label?: string;
  className?: string;
  checkBoxSize?: number;
}

export default function AppCheckBox({
  checked,
  label,
  className,
  checkBoxSize = 16,
  ...rest
}: AppCheckBoxProps) {
  return (
    <label className={clsx(styles.root, className)}>
      <span className={styles.checkboxContainer}>
        <input
          type='checkbox'
          className={styles.hiddenActualInput}
          checked={checked}
          {...rest}
        />
        <CheckBox
          checked={!!checked}
          width={checkBoxSize}
          height={checkBoxSize}
        />
      </span>
      {!!label && (
        <Typography
          variant='base'
          className={styles.label({ checked: !!checked })}
        >
          {label}
        </Typography>
      )}
    </label>
  );
}
