import './Typography.css';

import { ElementType, ReactNode } from 'react';
import React from 'react';

import { clsx } from '../../../utils';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subhead1'
  | 'subhead2'
  | 'lg'
  | 'base'
  | 'sm';

export interface TypographyProps {
  variant?: TypographyVariant;
  children?: ReactNode;
  as?: ElementType<any>;
  className?: string;
  noWrap?: boolean;
  [otherProps: string]: any;
}

export const Typography = React.forwardRef(function Typography(
  {
    variant = 'base',
    as: asProp,
    children,
    className,
    noWrap,
    ...rest
  }: TypographyProps,
  ref: React.ForwardedRef<any> | null,
) {
  const As =
    asProp !== undefined
      ? asProp
      : variant === 'h1' ||
          variant === 'h2' ||
          variant === 'h3' ||
          variant === 'h4' ||
          variant === 'h5' ||
          variant === 'h6'
        ? (variant as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6')
        : variant === 'subhead1' || variant === 'subhead2'
          ? 'h2'
          : variant === 'sm'
            ? 'span'
            : 'p';

  return (
    <As
      {...rest}
      className={clsx(
        `sphere-typography sphere-typography--${variant}`,
        noWrap && 'sphere-typography--nowrap',
        className,
      )}
      ref={ref}
    >
      {children}
    </As>
  );
});
