import {
  Network,
  PayoutNetwork,
  SolanaExplorer,
  type Token,
  type WidenEnum,
  isEvm,
} from '@spherelabs/common';
import { providers } from 'ethers';
import {
  type Chain,
  arbitrum,
  avalanche,
  base,
  bsc,
  gnosis,
  localhost,
  mainnet,
  optimism,
  polygon,
  sepolia,
} from 'wagmi/chains';
import { DEFAULT_RPC_URLS } from '../providers/EvmWalletProvider';
import { Blockchain } from '../types/enums';

export const getWagmiChainFromNetwork = (network: Network): Chain => {
  if (!isEvm(network)) {
    throw new Error('Only EVM networks are supported');
  }

  switch (network) {
    case Network.ETH_MAINNET:
      return mainnet;
    case Network.POLYGON_MAINNET:
      return polygon;
    case Network.GNOSIS_MAINNET:
      return gnosis;
    case Network.OPTIMISM_MAINNET:
      return optimism;
    case Network.ARBITRUM_MAINNET:
      return arbitrum;
    case Network.BSC_MAINNET:
      return bsc;
    case Network.SEPOLIA_TESTNET:
      return sepolia;
    case Network.BASE_MAINNET:
      return base;
    case Network.AVALANCHE_MAINNET:
      return avalanche;
    default:
      throw new Error('Unsupported network');
  }
};

export const getNetworkFromWagmiChain = (chain: Chain) => {
  switch (chain.id) {
    case mainnet.id:
      return Network.ETH_MAINNET;
    case polygon.id:
      return Network.POLYGON_MAINNET;
    case gnosis.id:
      return Network.GNOSIS_MAINNET;
    case optimism.id:
      return Network.OPTIMISM_MAINNET;
    case arbitrum.id:
      return Network.ARBITRUM_MAINNET;
    case bsc.id:
      return Network.BSC_MAINNET;
    case localhost.id:
      return Network.LOCALHOST;
    case sepolia.id:
      return Network.SEPOLIA_TESTNET;
    case base.id:
      return Network.BASE_MAINNET;
    default:
      console.error('Unsupported chain');
      return Network.ETH_MAINNET;
  }
};

export const getHumanReadableNetworkName = (network: Network) => {
  if (network === Network.SOL) {
    return 'Solana';
  }
  if (network === Network.OPTIMISM_MAINNET) {
    return 'Optimism';
  }
  if (isEvm(network)) {
    return getWagmiChainFromNetwork(network).name;
  }
  if (network === Network.BITCOIN) {
    return 'Bitcoin';
  }
  if (network === Network.STRIPE) {
    return 'Stripe';
  }

  return network.toString();
};

export const getExplorerTxBaseUrl = (network: Network) => {
  if (network === Network.SOL) {
    return 'https://solscan.io/tx';
  }
  if (network === Network.BITCOIN) {
    return 'https://blockstream.info/tx';
  }

  const wagmiChain = getWagmiChainFromNetwork(network) as Chain;
  const baseUrl = wagmiChain.blockExplorers?.default.url;
  if (!baseUrl) {
    return '';
  }

  return `${baseUrl}/tx`;
};

export const getExplorerAccountBaseUrl = (
  network: WidenEnum<Network | PayoutNetwork>,
  preferred?: SolanaExplorer,
) => {
  switch (network) {
    case Network.SOL || PayoutNetwork.SOL:
      switch (preferred) {
        case SolanaExplorer.EXPLORER:
          return 'https://explorer.solana.com/address';
        case SolanaExplorer.SOLSCAN:
          return 'https://solscan.io/account';
        case SolanaExplorer.SOLANAFM:
          return 'https://solana.fm/address';
        case SolanaExplorer.XRAY:
          return 'https://xray.helius.xyz/account';
        default:
          return 'https://solscan.io/account';
      }
    case Network.ETH_MAINNET || PayoutNetwork.ETH:
      return 'https://etherscan.io/address';
    case Network.POLYGON_MAINNET || PayoutNetwork.POLYGON:
      return 'https://polygonscan.com/address';
    case Network.OPTIMISM_MAINNET || PayoutNetwork.OPTIMISM:
      return 'https://optimistic.etherscan.io/address';
    case Network.ARBITRUM_MAINNET || PayoutNetwork.ARBITRUM:
      return 'https://arbiscan.io/address';
    case Network.BITCOIN:
      return 'https://www.blockchain.com/explorer/addresses/btc';
    default:
      return 'https://solscan.io/account';
  }
};

export const getExplorerTransactionBaseUrl = (
  network: WidenEnum<Network | PayoutNetwork>,
  preferred?: SolanaExplorer,
) => {
  switch (network) {
    case Network.SOL || PayoutNetwork.SOL:
      switch (preferred) {
        case SolanaExplorer.EXPLORER:
          return 'https://explorer.solana.com/tx';
        case SolanaExplorer.SOLSCAN:
          return 'https://solscan.io/tx';
        case SolanaExplorer.SOLANAFM:
          return 'https://solana.fm/tx';
        case SolanaExplorer.XRAY:
          return 'https://xray.helius.xyz/tx';
        default:
          return 'https://solscan.io/tx';
      }
    case Network.ETH_MAINNET || PayoutNetwork.ETH:
      return 'https://etherscan.io/tx';
    case Network.POLYGON_MAINNET || PayoutNetwork.POLYGON:
      return 'https://polygonscan.com/tx';
    case Network.OPTIMISM_MAINNET || PayoutNetwork.OPTIMISM:
      return 'https://optimistic.etherscan.io/tx';
    case Network.ARBITRUM_MAINNET || PayoutNetwork.ARBITRUM:
      return 'https://arbiscan.io/tx';
    case Network.BITCOIN:
      return 'https://www.blockchain.com/explorer/transactions/btc';
    default:
      return 'https://solscan.io/account';
  }
};

export const getEvmProvider = (network: Network) => {
  const rpcUrl = DEFAULT_RPC_URLS[getWagmiChainFromNetwork(network).id];

  if (rpcUrl) {
    const provider = new providers.JsonRpcProvider(rpcUrl);
    return provider;
  }
  switch (network) {
    case Network.GNOSIS_MAINNET:
      return new providers.JsonRpcProvider(
        process.env.NEXT_PUBLIC_GNOSIS_FALLBACK_RPC_URL,
      );
    case Network.BSC_MAINNET:
      return new providers.JsonRpcProvider(
        process.env.NEXT_PUBLIC_BSC_FALLBACK_RPC_URL,
      );
    default:
      throw new Error(`Network ${network} has no infura provider`);
  }
};

export function getBlockchainNativeCurrency(network: Blockchain): {
  name: string;
  symbol: string;
} {
  if (network === Network.SOL) {
    return { name: 'Solana', symbol: 'SOL' };
  }
  if (isEvm(network)) {
    return getWagmiChainFromNetwork(network).nativeCurrency;
  }

  // if (network === Network.BITCOIN) {
  return { name: 'Bitcoin', symbol: 'BTC' };
  // }

  // const networkString = network.toString();
  // return { name: networkString, symbol: networkString };
}
