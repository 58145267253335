import './SphereDialog.css';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { clsx } from '../../../utils/clsx';
import { SphereModal } from '../SphereModal';
import { SphereModalBackdrop } from '../SphereModalBackdrop';
import {
  TransitionProps,
  fadeTransitionProps,
} from '../styles/transitionProps';

export interface SphereDialogProps {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  transitionProps?: TransitionProps;
  className?: string;
  initialFocus?: React.RefObject<any>;
}

export const SphereDialog = ({
  open,
  onClose,
  children,
  className,
  transitionProps,
  initialFocus,
}: SphereDialogProps) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as='div'
        className={clsx('sphere-dialog !z-modal')}
        onClose={() => {
          if (onClose) {
            onClose();
          }
        }}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          {...(transitionProps ?? fadeTransitionProps)}
        >
          <SphereModalBackdrop />
        </Transition.Child>
        <div className='sphere-dialog__overlay'>
          <div className='sphere-dialog__positioner'>
            <Transition.Child
              as={Fragment}
              {...(transitionProps ?? fadeTransitionProps)}
            >
              <Dialog.Panel as={Fragment}>
                <SphereModal
                  className={clsx('sphere-dialog__modal', className)}
                >
                  {children}
                </SphereModal>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
