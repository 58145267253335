import './SphereInputLabel.css';

import { cn } from '../../../utils';
import { Typography } from '../Typography';

interface SphereInputLabelProps {
  children: React.ReactNode;
  required?: boolean;
  withBackground?: boolean;
  className?: string;
}

export const SphereInputLabel = ({
  children,
  withBackground,
  required,
  className,
}: SphereInputLabelProps) => (
  <Typography
    variant='subhead1'
    as='label'
    className={cn(
      'sphere-input-label',
      withBackground && 'sphere-input-label--with-background',
      'text-base',
      className,
    )}
  >
    {children}
    {required && (
      <>
        {' '}
        <RequiredAsterisk />
      </>
    )}
  </Typography>
);

export const RequiredAsterisk = () => (
  <span className='sphere-input-label__required'>*</span>
);
