import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useCallback, useEffect, useRef } from 'react';
import {
  Config,
  useAccount,
  UseAccountReturnType,
  useDisconnect,
  useSwitchChain,
} from 'wagmi';
import { create } from 'zustand';

interface ConnectEvmWalletPromiseInfo {
  resolve: (account: UseAccountReturnType<Config>) => void;
  reject: (error?: Error) => void;
  chainId: number;
}

interface DisconnectEvmWalletPromiseInfo {
  resolve: (openConnectModal: () => void) => void;
  reject: (error?: Error) => void;
}

export function useConnectEvmWallet() {
  const {
    promise,
    setPromise,
    walletDisconnectPromise,
    setWalletDisconnectPromise,
  } = useConnectEvmWalletStore();

  const { openConnectModal, connectModalOpen } = useConnectModal();
  const { switchChainAsync } = useSwitchChain();
  const { disconnectAsync } = useDisconnect();
  const account = useAccount();
  const { status, isConnected } = account;

  const handleConnect = useCallback(
    async (chainId: number) => {
      if (status === 'connecting' || status === 'reconnecting') {
        return;
      }

      disconnectAsync();
      const openConnectModalLatest = await new Promise<() => void>(
        (resolve, reject) => {
          setWalletDisconnectPromise({ resolve, reject });
        },
      );

      openConnectModalLatest();

      return new Promise<UseAccountReturnType<Config>>((resolve, reject) => {
        setPromise({ resolve, reject, chainId });
      });
    },
    [status, setPromise, disconnectAsync, setWalletDisconnectPromise],
  );

  useEffect(
    function resolveDisconnect() {
      if (!walletDisconnectPromise) return;

      if (openConnectModal) {
        walletDisconnectPromise.resolve(openConnectModal);
        setWalletDisconnectPromise(null);
      }
    },
    [openConnectModal, walletDisconnectPromise, setWalletDisconnectPromise],
  );

  const isResolvingWalletConnection = useRef(false);
  const resolveWalletConnection = useCallback(async () => {
    if (!promise || !account.address || isResolvingWalletConnection.current) {
      return;
    }

    isResolvingWalletConnection.current = true;
    try {
      if (account.chainId !== promise.chainId) {
        try {
          await switchChainAsync({
            chainId: promise.chainId,
          });
        } catch (e) {
          if (
            e instanceof Error &&
            (e.message.includes(
              `Request of type 'wallet_switchEthereumChain' already pending`,
            ) ||
              e.message.includes(
                'The request has been rejected due to a change in selected network. Please verify the selected network and retry the request.',
              ))
          ) {
            // ignore multiple chain switch errors and prevent resolve of promise
            return;
          }
          throw e;
        }
      }

      promise.resolve(account);
    } catch (e: any) {
      const errorMessage =
        e.shortMessage || 'An error occurred while switching chain';
      await disconnectAsync();
      promise.reject(new Error(errorMessage));
    } finally {
      setPromise(null);
      isResolvingWalletConnection.current = false;
    }
  }, [promise, setPromise, account, switchChainAsync, disconnectAsync]);

  useEffect(
    function handleConnectEvmWalletPromise() {
      if (!promise) {
        return;
      }

      if (isConnected) {
        resolveWalletConnection();
        return;
      }
    },
    [promise, isConnected, resolveWalletConnection],
  );

  const wasConnectModalOpen = useRef(false);
  useEffect(
    function handleCloseModal() {
      if (
        wasConnectModalOpen.current &&
        !connectModalOpen &&
        promise &&
        !isConnected
      ) {
        promise.reject(new Error('User rejected the connection'));
        setPromise(null);
      }
      wasConnectModalOpen.current = connectModalOpen;
    },
    [connectModalOpen, promise, setPromise, isConnected],
  );

  return handleConnect;
}

interface ConnectEvmWalletState {
  promise: ConnectEvmWalletPromiseInfo | null;
  setPromise: (promise: ConnectEvmWalletPromiseInfo | null) => void;

  walletDisconnectPromise: DisconnectEvmWalletPromiseInfo | null;
  setWalletDisconnectPromise: (
    promise: DisconnectEvmWalletPromiseInfo | null,
  ) => void;
}

const useConnectEvmWalletStore = create<ConnectEvmWalletState>()((set) => ({
  promise: null,
  setPromise: (promise) => set({ promise }),

  walletDisconnectPromise: null,
  setWalletDisconnectPromise: (promise) =>
    set({ walletDisconnectPromise: promise }),
}));
