import { RecurringInterval } from '@spherelabs/common';

export const getSubscriptionBillDuration = (recurring: {
  interval: RecurringInterval;
  intervalCount: number;
}) => {
  switch (recurring.interval) {
    case RecurringInterval.MONTH:
      if (recurring.intervalCount === 1) {
        return 'Monthly';
      }
      return `Every ${recurring.intervalCount} Months`;
    case RecurringInterval.YEAR:
      if (recurring.intervalCount === 1) {
        return 'Yearly';
      }
      return `Every ${recurring.intervalCount} Years`;
    case RecurringInterval.WEEK:
      if (recurring.intervalCount === 1) {
        return 'Weekly';
      }
      return `Every ${recurring.intervalCount} Weeks`;
    case RecurringInterval.DAY:
      if (recurring.intervalCount === 1) {
        return 'Daily';
      }
      return `Every ${recurring.intervalCount} Days`;
    case RecurringInterval.MIN:
      if (recurring.intervalCount === 1) {
        return 'Every Minute';
      }
      return `Every ${recurring.intervalCount} Minutes`;
  }
};
