export type TransitionProps = {
  enter: string;
  enterFrom: string;
  enterTo: string;
  leave: string;
  leaveFrom: string;
  leaveTo: string;
};

export const fadeTransitionProps = {
  enter: 'sphere-transition-enter',
  enterFrom: 'sphere-transition-enter-from-fade',
  enterTo: 'sphere-transition-enter-to-fade',
  leave: 'sphere-transition-leave',
  leaveFrom: 'sphere-transition-leave-from-fade',
  leaveTo: 'sphere-transition-leave-to-fade',
};

export const slideUpTransitionProps = {
  enter: 'sphere-transition-enter',
  enterFrom: 'sphere-transition-enter-from-slide-up',
  enterTo: 'sphere-transition-enter-to-slide-up',
  leave: 'sphere-transition-leave',
  leaveFrom: 'sphere-transition-leave-from-slide-up',
  leaveTo: 'sphere-transition-leave-to-slide-up',
};

export const growTransitionProps = {
  enter: 'ease-linear duration-1000',
  enterFrom: 'max-h-0',
  enterTo: 'max-h-[800px]',
  leave: 'ease-linear duration-1000',
  leaveFrom: 'max-h-[800px]',
  leaveTo: 'max-h-0',
};
