import { SVGProps } from 'react';

export default function ImageIcon({
  color,
  opacity,
  ...rest
}: SVGProps<SVGSVGElement> & { color?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='14'
      fill='none'
      viewBox='0 0 15 14'
      {...rest}
    >
      <path
        stroke={color ?? '#2C61F9'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1'
        d='M2.573 12.366L6.78 8.161c.252-.253.378-.379.524-.426a.637.637 0 01.394 0c.146.047.272.173.524.426l4.178 4.177M8.774 8.714l1.829-1.828c.252-.253.378-.379.524-.426a.637.637 0 01.394 0c.146.047.272.173.524.426l1.829 1.828M6.225 4.89a1.275 1.275 0 11-2.55 0 1.275 1.275 0 012.55 0zm-2.04 7.65h6.63c1.071 0 1.607 0 2.016-.208.36-.183.652-.476.835-.836.209-.409.209-.944.209-2.016V4.124c0-1.07 0-1.606-.209-2.015a1.912 1.912 0 00-.835-.836c-.41-.209-.945-.209-2.016-.209h-6.63c-1.071 0-1.607 0-2.016.209-.36.183-.652.476-.836.836-.208.409-.208.944-.208 2.015V9.48c0 1.072 0 1.607.208 2.016.184.36.476.653.836.836.41.209.945.209 2.016.209z'
        opacity={opacity}
      />
    </svg>
  );
}
